<template>
  <div data-vue-component-name="Webinars">
    <img
      width="1920"
      height="1024"
      src="backgrounds/Webinars.png"
      alt="page background image"
    >

    <!-- navigation -->
    <transition name="fade" appear>
      <AppWrapper
        v-if="webinars?.length"
        class="navigation"
        tag="aside"
        bg-color="rgba(0, 0, 0, 0.2)"
        blur="32"
      >
        <ButtonBack @click="getBack"/>

        <div class="title">
          <h2>Webinars</h2>
          &nbsp;
          <span>{{ webinars?.length }}</span>
        </div>

        <ul class="webinars">
          <li
            v-for="(webinar, index) in webinars"
            :key="webinar.name"
            class="webinar"
            :class="{ active: activeWebinarIndex === index }"
            @click="setActiveWebinarIndex(index)"
          >
            <div class="title">
              <span class="name">{{ webinar.name }}</span>
              <time class="date">{{ webinar.date }}</time>
            </div>

            <div class="description" v-html="webinar.description"/>

            <div class="authors">
              <span>By</span>
              &nbsp;

              <template
                v-for="(author, index) in webinar.authors"
                :key="index"
              >
                <span class="author">{{ author.name }}</span>

                <span v-if="index !== (webinar.authors.length - 1)">
                  &nbsp;/&nbsp;
                </span>
              </template>
            </div>
          </li>
        </ul>
      </AppWrapper>
    </transition>

    <!-- webinar info -->
    <transition name="fade" appear mode="out-in">
      <section
        v-if="activeWebinar"
        :key="activeWebinar"
        class="content"
      >
        <div class="webinar">
          <h3 class="name">{{ activeWebinar.name }}</h3>
          <div class="description" v-html="activeWebinar.description"/>

          <WebinarsSpeakers
            :date="activeWebinar.date"
            :duration="activeWebinar.long"
            :authors="activeWebinar.authors"
          />

          <ul class="videos">
            <li
              v-for="(video, index) in activeWebinar.videoList"
              :key="index"
              class="video"
            >
              <h4 class="name">{{ video.name }}</h4>

              <YoutubePlayerWithForm
                :video-link="video.video"
                :title="accessInfo.leftColumn.title"
                :description="accessInfo.leftColumn.description"
                :form="accessInfo.rightColumn.getAccessForm"
                @on-form-submit="values => sendForm(video, values)"
              />

              <div class="description" v-html="video.description" />
            </li>
          </ul>
        </div>
      </section>
    </transition>
  </div>
</template>

<script setup>
import { metaController } from '@/modules';

// components
import { AppWrapper, ButtonBack, WebinarsSpeakers } from '@xsys2/components';
import YoutubePlayerWithForm from '@/components/YoutubePlayerWithForm.vue';

// dependencies
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { transformDataToHtmlTable } from '@xsys2/functions';

// meta
metaController({
  title: 'Webinars Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/Webinars.png',
      as: 'image',
    },
  ],
});

const router = useRouter();
const store = useStore();

store.dispatch('webinars/getData');

const getBack = () => router.go(-1);

// store
const webinars = computed(() => store.state.webinars.webinarsList);
const accessInfo = computed(() => store.state.webinars.getAccessForm);

const activeWebinarIndex = ref(0);
const setActiveWebinarIndex = index => (activeWebinarIndex.value = index);
const activeWebinar = computed(() => webinars.value[activeWebinarIndex.value]);

const sendForm = (video, { values, callback }) => store
  .dispatch(
    'sendForm',
    {
      subject: video.name,
      message: transformDataToHtmlTable(values),
    },
  )
  .then(callback);
</script>

<style scoped lang="scss">
[data-vue-component-name="Webinars"] {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;

  & > {
    img {
      @include absolute-full;
      z-index: 0;
      width: inherit;
      height: inherit;
      object-fit: cover;
    }

    .navigation {
      $offset: 1.667vw;

      z-index: 1;
      max-width: 20.833vw;
      width: 100%;
      padding: $offset;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > {
        [data-vue-component-name=ButtonBack] {
          margin-bottom: 0.990vw;
        }

        .title {
          font-weight: 800;
          font-size: 1.667vw;
          line-height: 2.292vw;
          text-transform: uppercase;
          margin-bottom: 3.333vw;

          h2 {
            display: inline;
            color: #FFFFFF;
          }

          span {
            display: inline;
            color: map-get($theme-colors, 'primary');
          }
        }

        .webinars {
          margin: 0 0;
          overflow-y: auto;
          margin: 0 -#{$offset};

          .webinar {
            color: #FFFFFF;
            font-style: normal;
            padding: 1.250vw $offset;
            cursor: pointer;
            background-color: transparent;
            transition: background-color 0.15s ease-in-out;

            &.active {
              background-color: map-get($theme-colors, 'primary');
            }

            .title {
              margin-bottom: 0.469vw;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .name {
                font-weight: 800;
                font-size: 0.938vw;
                line-height: 1.302vw;
              }

              .date {
                font-weight: normal;
                font-size: 0.729vw;
                line-height: 0.990vw;
                opacity: 0.75;
              }
            }

            .description {
              margin-bottom: 0.417vw;

              &::v-deep(p) {
                font-weight: normal;
                font-size: 0.729vw;
                line-height: 150%;
                opacity: 0.75;
              }
            }

            .authors {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              font-size: 0.729vw;
              line-height: 0.990vw;

              span {
                font-weight: normal;
                opacity: 0.75;

                &.author {
                  font-weight: bold;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    .content {
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .webinar {
        color: #FFFFFF;
        font-style: normal;
        margin: 9.7vw 10vw;

        & > {
          .name {
            font-weight: 800;
            font-size: 1.667vw;
            line-height: 2.292vw;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 1.667vw;
          }

          .description {
            margin-bottom: 1.667vw;

            &::v-deep(p) {
              font-weight: normal;
              font-size: 0.938vw;
              line-height: 150%;
              opacity: 0.88;
            }
          }

          [data-vue-component-name=WebinarsSpeakers] {
            margin-bottom: 1.667vw;
          }

          .videos {

            .video {
              margin-bottom: 3.333vw;

              &:last-child {
                margin-bottom: 0;
              }

              .name {
                font-weight: bold;
                font-size: 1.250vw;
                line-height: 150%;
                margin-bottom: 1.667vw;
              }

              [data-vue-component-name=YoutubePlayerWithForm] {
                margin-bottom: 1.667vw;
              }

              .description {
                font-weight: normal;
                font-size: 0.938vw;
                line-height: 150%;
                opacity: 0.88;
              }
            }
          }
        }
      }
    }
  }
}
</style>
